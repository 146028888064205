<template>
  <div class="mini_game_bet">

    <div class="mini_game">
      <div class="m1 dmr">
        <div class="mini_fram">
          <leisure-game-ifram-view>
            <iframe id="crown-gamefram" scrolling="no" frameborder="0"
                    :src="iframniballUrl"></iframe>
          </leisure-game-ifram-view>
        </div>
      </div>
      <div class="m2">
        <div class="game_tab">
          <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
          <leisure-timer-comp
                  ref="ltimer"
                  :leisure-game-info="leisureGameInfo"
                  @timerStop="timerStop" @refreshTimer="refreshTimer">
            <span slot="title" class="text-orange"><i class="fa fa-star"></i>크라운 나인볼 </span>
          </leisure-timer-comp>

          <!--선택부분-->
          <div class="mini_bet_box">

            <div class="mg">
              <span class="t">끗수게임</span>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NINEBALL_WINNUMBER && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NINEBALL_WINNUMBER,leisureGameInfo.leisureGame.id,1, leftrightConfig.odds1, leftrightConfig, 'Left승')">
                <div class="r rblue">
                  <span class="n">Left</span>
                  <span class="b">{{leftrightConfig.odds1}}</span>
                </div>
              </div>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NINEBALL_WINNUMBER && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NINEBALL_WINNUMBER,leisureGameInfo.leisureGame.id,2, leftrightConfig.odds2, leftrightConfig, 'Right승')">
                <div class="r rred">
                  <span class="n">Right</span>
                  <span class="b">{{leftrightConfig.odds2}}</span>
                </div>
              </div>
            </div>

            <div class="mg">
              <span class="t">홀/짝</span>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NINEBALL_ODDEVEN && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NINEBALL_ODDEVEN,leisureGameInfo.leisureGame.id,1, oddevenConfig.odds1, oddevenConfig, '홀')">
                <div class="r rblue">
                  <span class="n">홀</span>
                  <span class="b">{{oddevenConfig.odds1}}</span>
                </div>
              </div>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NINEBALL_ODDEVEN && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NINEBALL_ODDEVEN,leisureGameInfo.leisureGame.id,2,  oddevenConfig.odds2, oddevenConfig, '짝')">
                <div class="r rred">
                  <span class="n">짝</span>
                  <span class="b">{{oddevenConfig.odds2}}</span>
                </div>
              </div>
            </div>

            <div class="mg">
              <span class="t">마지막볼 BLUE/RED 게임</span>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NINEBALL_BALLCOLOR && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NINEBALL_BALLCOLOR,leisureGameInfo.leisureGame.id,1, colorConfig.odds1, colorConfig, 'BLUE')">
                <div class="r rblue">
                  <span class="n">BLUE</span>
                  <span class="b">{{colorConfig.odds1}}</span>
                </div>
              </div>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NINEBALL_BALLCOLOR && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NINEBALL_BALLCOLOR,leisureGameInfo.leisureGame.id,2, colorConfig.odds2, colorConfig, 'RED')">
                <div class="r rred">
                  <span class="n">RED</span>
                  <span class="b">{{colorConfig.odds2}}</span>
                </div>
              </div>
            </div>

          </div>

          <!--파워볼 베팅카트-->
          <leisure-bet-cart-comp
                  ref="betCart" :kind="kind"
                  :selected-info="selectedInfo">
            나인볼
          </leisure-bet-cart-comp>


        </div>
      </div>

      <div class="m3">
        <!--최근 배팅내역-->
        <leisure-lately-bet-list-comp
                ref="latelyBetList"
                :kind="kind"></leisure-lately-bet-list-comp>

      </div>




    </div>
  </div>
</template>

<script>
  import SubTitle from "../../../components/SubTitle";
  import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
  import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
  import leisureConst from "../../../common/leisureConst";
  import sportsConst from "../../../common/sportsConst";
  import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
  import {leisureGameMixin} from "../../../common/mixin";
  import {getLeisureGameAttributeConfig} from "../../../network/leisureRequest";
  import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
  import LeisureGameView from "../../../components/leisuregame/LeisureGameView";
  import LeisureGameLinks from "../LeisureGameLinks";
  import LeisureGameIframView from "../LeisureGameIframView";
  import LeisureCompGameLinks from "../LeisureCompDonghangGameLinks";
  import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "../../../store/mutation-types";

  export default {

    name: "CrownNineBall",
    mixins:[leisureGameMixin],
    components: {
      LeisureCompGameLinks,
      LeisureGameIframView,
      LeisureGameLinks,
      LeisureGameView,
      LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle},
    data() {
      return {
        kind: leisureConst.LEISURE_KIND_NINEBALL,
        iframniballUrl:'',
      }
    },

    methods:{
      initLeisureGameArttributeConfig() {
        getLeisureGameAttributeConfig().then(res => {
          if (res.data.success) {
            this.attrConfig = res.data.data

            //배당설정
            this.oddevenConfig = this.attrConfig.find(attr => {
              return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_NINEBALL_ODDEVEN)
            })

            this.leftrightConfig = this.attrConfig.find(attr => {
              return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_NINEBALL_WINNUMBER)
            })

            this.colorConfig = this.attrConfig.find(attr => {
              return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_NINEBALL_BALLCOLOR)
            })
          }
        })
      }
    },
    created() {
      if(this.$store.state.ismobile){
        this.iframniballUrl = 'https://str02.crown-api02.com/iframe/mobile';
      }else {
        this.iframniballUrl = 'https://str02.crown-api02.com/iframe/pc';
      }
      this.initKindConfig(this.kind)
      this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP,leisureConst.LEISURE_COMP_CROWN);
      this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID,this.kind);
    },
  }
</script>

<style scoped>
  @media screen and (max-width: 1024px) {
    .dmr{
      min-height: 680px!important;
    }
  }
</style>